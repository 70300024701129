<template>

  <v-virtual-scroll
      :items="items"
      :height="height"
      item-height="64"
      v-if="items.length > 0"
  >
    <template v-slot:default="{ item }">
      <v-list-item :key="item.id" @click="openTask(item.$id)">
        <v-list-item-action>
          <v-btn
              fab
              small
              depressed
              color=""
              @click="openTask(item.$id)"
          >
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            <div class="d-inline ps-2">
                <strong>{{item.invoiceNumber}} | {{ item.subject }}</strong>
            </div>
            <span v-if="item.status" class="ps-2">
              <v-icon>mdi-circle-small</v-icon>
              <span v-if="item.status === 0" >In Erstellung</span>
              <span v-if="item.status === 1" >versendet</span>
              <span v-if="item.status === 2" >bezahlt</span>
              <span v-if="item.status === 3" >storniert</span>
            </span>
            <span v-if="item.invoiceDate" class="ps-2">
              <v-icon>mdi-circle-small</v-icon>
              <v-icon>mdi-calendar</v-icon>
              {{ getStringDate(item.invoiceDate) }}
            </span>
            <span v-if="item.price">
              <v-icon>mdi-circle-small</v-icon>
              {{ replaceDot(item.price) }}€
            </span>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action @click="openTask(item.$id)">
          <v-icon small>
            mdi-arrow-right
          </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-virtual-scroll>
  <NoResultSnippet
      v-else
      :text="noResultsText">
  </NoResultSnippet>
</template>

<script>
import {date2String, dotToKomma} from "@/helper/helper";

const NoResultSnippet = () => import("@/components/generalUI/NoResultSnippet");

export default {
  name: "Message",
  components: {
    NoResultSnippet
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    items: {
      type: Array,
      default() {
        return []
      }
    },
    height: {
      type: Number,
      default() {
        return 320
      }
    },
    noResultsText: String
  },
  methods: {
    openTask(id) {
      this.$router.push('/rechnungen/rechnung/' + id)
    },
    getStringDate(date) {
      if(date){
        return date2String(date)
      }
      return ''
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
  }
}
</script>
